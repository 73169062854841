import { faWordpress } from '@fortawesome/free-brands-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, Typography, Radio, TextField, Button } from '@material-ui/core'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main,
        color: '#fff'
    },
    radioDiv: {
        textAlign: 'center'
    },
    icon: {
        fontSize: 50
    }
}))

export default function AddSite({ open, closeDialog }) {
    const classes = useStyles()
    const { addNewSite } = useDb()

    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    
    const handleClose = () => {
        setLoading(true)
        setError(null)
        setState({})
        closeDialog()
    }

    const handleRadio = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        try {
            await addNewSite(state)
            handleClose()
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }
    
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.header}>
                Add Site
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <div className={classes.radioDiv}>
                            <FontAwesomeIcon className={classes.icon} icon={faWordpress} />
                            <Typography>WordPress</Typography>
                            <Radio
                                checked={state.siteType === 'wordpress'}
                                onChange={handleRadio}
                                value="wordpress"
                                name="siteType"
                                color="primary"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.radioDiv}>
                            <FontAwesomeIcon className={classes.icon} icon={faCode} />
                            <Typography>Static HTML</Typography>
                            <Radio
                                checked={state.siteType === 'static'}
                                onChange={handleRadio}
                                value="static"
                                name="siteType"
                                color="primary"
                            />
                        </div>
                    </Grid>
                </Grid>
                {
                    state.siteType === 'wordpress'
                    ?
                    <div>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                {...textFieldProps}
                                label="Site URL"
                                onChange={handleChange}
                                name="url"
                                type="url"
                                required
                            />
                            <TextField
                                {...textFieldProps}
                                label="Site Title"
                                onChange={handleChange}
                                name="title"
                                required
                            />
                            <TextField
                                {...textFieldProps}
                                label="Admin User Name"
                                onChange={handleChange}
                                name="username"
                                required
                            />
                            <TextField
                                {...textFieldProps}
                                label="Admin Password"
                                onChange={handleChange}
                                name="password"
                                type="password"
                                required
                            />
                            {
                                error 
                                ?
                                <span className="error">
                                    {error}
                                </span>
                                :
                                null
                            }
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading}
                            >
                                Add Site
                            </Button>
                        </form>
                    </div>
                    :
                    null
                }
            </DialogContent>
        </Dialog>
    )
}
