import React, { useContext, useState, useEffect } from 'react'
import { db, fieldValue } from '../firebase'
import { useAuth } from './AuthContext'

const DatabaseContext = React.createContext()

export function useDb() {
    return useContext(DatabaseContext)
}

export default function DatabaseProvider({ children }) {
    const { currentUser } = useAuth()

    // Get Stripe Products
    async function getProducts() {
        return db.collection('products')
        .where('active', '==', true)
        .get()
        .then(async (snap) => {
            let products = []
            for(let i = 0; i < snap.docs.length; i++) {
                const doc = snap.docs[i]
                const priceSnap = await doc.ref.collection('prices')
                .where('active', '==', true)
                .get();

                const prices = priceSnap.docs.map((priceDoc) => ({
                    ...priceDoc.data(),
                    id: priceDoc.id
                }))

                products.push({
                    ...doc.data(),
                    prices,
                    id: doc.id
                })
            }
            return products
        });
    }

    // User Checkout
    async function checkout(price) {
        const docRef = await db
        .collection('users')
        .doc(currentUser.uid)
        .collection('checkout_sessions')
        .add({
            price: price,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
            if (error) {
                // Show an error to your customer and 
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        });
    }

    // Get a customers subscription
    function GetSubscription() {
        const [sub, setSub] = useState()
        useEffect(() => {
            const unsubscribe = db.collection('users')
            .doc(currentUser.uid)
            .collection('subscriptions')
            .where('status', 'in', ['trialing', 'active'])
            .onSnapshot(async (snapshot) => {
                if(!snapshot.empty) {
                    // In this implementation we only expect one active or trialing subscription to exist.
                    const doc = snapshot.docs[0];
                    setSub({
                        ...doc.data(),
                        id: doc.id
                    })
                }
            });
            return unsubscribe
        }, [])
        return sub
    }

    // Adding a new website
    async function addNewSite(data) {
        return await db.collection('sites')
        .add({
            ...data,
            owner: currentUser.uid,
            added: fieldValue.serverTimestamp()
        })
    }

    // Get all of a users websites
    function GetUserSites() {
        const [sites, setSites] = useState([])

        useEffect(() => {
            const unsubscribe = db
            .collection('sites')
            .where('owner', '==', currentUser.uid)
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setSites(data)
            })
            return unsubscribe
        }, [])
        return sites
    }

    // Create a new Task
    async function createTask(data) {
        return await db.collection('tasks')
        .add({
            owner: currentUser.uid,
            ...data,
            timestamp: fieldValue.serverTimestamp(),
            open: true
        })
    }

    // Get all of a sites tasks
    function GetSiteTasks(siteId) {
        const [tasks, setTasks] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('tasks')
            .where('owner', '==', currentUser.uid)
            .where('siteId', '==', siteId)
            .where('open', '==', true)
            .orderBy('timestamp', 'desc')
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setTasks(data)
            })
            return unsubscribe
        }, [siteId])
        return tasks
    }

    // Get all of a sites closed tasks
    function GetClosedTasks(siteId) {
        const [tasks, setTasks] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('tasks')
            .where('owner', '==', currentUser.uid)
            .where('siteId', '==', siteId)
            .where('open', '==', false)
            .orderBy('timestamp', 'desc')
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setTasks(data)
            })
            return unsubscribe
        }, [siteId])
        return tasks
    }

    // Get a single task
    async function getTask(taskId) {
        return await db.collection('tasks')
        .doc(taskId)
        .get()
        .then(doc => {
            return {
                ...doc.data(),
                id: doc.id
            }
        })
    }

    // Mark task as closed
    async function closeTask(taskId) {
        return await db.collection('tasks')
        .doc(taskId)
        .update({
            open: false,
            closedTimestamp: fieldValue.serverTimestamp(),
            closedBy: currentUser.uid
        })
    }

    // Send Chat Message
    async function sendChat(message, taskId) {
        return new Promise(async (res, rej) => {
            const task = await db.collection('tasks').doc(taskId)
            .get().then(doc => {
                return {
                    ...doc.data(),
                    id: doc.id
                }
            })

            const attendees = task.agents ? [...task.agents, task.owner] : [task.owner]

            await db.collection('messages')
            .add({
                message,
                timestamp: fieldValue.serverTimestamp(),
                sender: currentUser.uid,
                attendees,
                taskId
            })

            res()
        })
    }

    // Get Chat thread for task
    function GetTaskChats(taskId) {
        const [chats, setChats] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('messages')
            .where('taskId', '==', taskId)
            .where('attendees', 'array-contains', currentUser.uid)
            .orderBy('timestamp')
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setChats(data)
            })
            return unsubscribe
        }, [taskId])
        return chats
    }

    // Get All Incoming Tasks, this is for agents only.
    function GetIncomingTasks() {
        const [tasks, setTasks] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('tasks')
            .where('open', '==', true)
            .orderBy('timestamp', 'desc')
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setTasks(data)
            })
            return unsubscribe
        }, [])
        return tasks
    }

    // Agent adds themselves to a task
    async function joinTask(taskId) {
        return await db.collection('tasks')
        .doc(taskId)
        .update({
            agents: fieldValue.arrayUnion(currentUser.uid)
        })
    }

    const value = {
        getProducts,
        checkout,
        addNewSite,
        GetUserSites,
        createTask,
        GetSiteTasks,
        GetSubscription,
        getTask,
        sendChat,
        GetTaskChats,
        closeTask,
        GetClosedTasks,
        GetIncomingTasks,
        joinTask
    }

    return (
        <DatabaseContext.Provider value={value}>
            { children }
        </DatabaseContext.Provider>
    )
}
