import { Avatar, Card, CardHeader, makeStyles } from '@material-ui/core'
import { AttachFile } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    avatar: {
        background: theme.palette.primary.main
    },
}))

export default function Attachments({ taskId }) {
    const classes = useStyles()

    return (
        <Card>
            <CardHeader
                title="Attachments"
                avatar={
                    <Avatar className={classes.avatar}>
                        <AttachFile />
                    </Avatar>
                }
            />
        </Card>
    )
}
