import { Button, Container, Grid } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import CompletedTask from './CompletedTask'

export default function ClosedTasks() {
    const { siteId } = useParams()
    const history = useHistory()
    const { GetClosedTasks } = useDb()
    const tasks = GetClosedTasks(siteId)

    return (
        <div>
            <Container>
                <h1>Closed Tasks</h1>
                <Button 
                    startIcon={<ArrowBack />}
                    color="primary"
                    onClick={() => history.goBack()}
                >   
                    Back
                </Button>
                <Grid container spacing={3}>
                    {
                        tasks && tasks.map(task => (
                            <Grid item xs={12} md={4} key={task.id}>
                                <CompletedTask task={task} />
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </div>
    )
}
