import { Container, Grid } from '@material-ui/core'
import React from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import AgentTask from './AgentTask'

export default function IncomingTasks() {
    const { GetIncomingTasks } = useDb()
    const { currentUser } = useAuth()
    const tasks = GetIncomingTasks()
    console.log(tasks)
    return (
        <div>
            <Container>
                <h1>Incoming Tasks</h1>
                <Grid container spacing={3}>
                    {
                        tasks && tasks.map(t => (
                            <Grid item xs={12} md={4} key={t.id}>
                                <AgentTask 
                                    task={t}
                                    currentUser={currentUser}
                                />
                            </Grid> 
                        ))
                    }
                </Grid>
            </Container>
        </div>
    )
}
