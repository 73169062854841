import { Avatar, Card, CardActions, CardHeader, CardContent, makeStyles, Button } from '@material-ui/core'
import React from 'react'
import BuildIcon from '@material-ui/icons/Build';
import HelpIcon from '@material-ui/icons/Help';
import { Chat, CheckCircle } from '@material-ui/icons';
import moment from 'moment'
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    card: {
        height: 250,
        display: 'flex',
        flexDirection: 'column'
    },
    actions: {
        marginTop: 'auto'
    },
    url: {
        fontSize: 12
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: 35
    }
}))

export default function ClientTask({ task, confirmClose }) {
    const classes = useStyles()

    return (
        <Card className={classes.card}>
            <CardHeader 
                title={`${task.title}`}
                subheader={task.timestamp && moment(task.timestamp.toDate()).fromNow()}
                avatar={
                    <Avatar>
                        {
                            task.waiting
                            ?
                            <HelpIcon />
                            :
                            <BuildIcon />
                        }
                    </Avatar>
                }
            />
            <CardContent>
                {/* Task Preview */}
            </CardContent>
            <CardActions className={classes.actions}>
                <Link to={`/workspace/${task.id}`}>
                    <Button color="primary" startIcon={<Chat />}>Workspace</Button>
                </Link>
                <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={<CheckCircle />}
                    onClick={() => confirmClose(task)}
                >
                    Close
                </Button>
            </CardActions>
        </Card>
    )
}
