import { Card, Grid, makeStyles, CardContent } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import AddSite from './AddSite'
import SiteCard from './SiteCard'

const useStyles = makeStyles((theme) => ({
    card: {
        height: 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    cardContent: {
        textAlign: 'center'
    },
    addIcon: {
        fontSize: 40,
        color: theme.palette.primary.main,
        textAlign: 'center'
    }
}))

export default function MySites() {
    const classes = useStyles()
    const { GetUserSites } = useDb()

    const [open, setOpen] = React.useState(false)

    const sites = GetUserSites()

    return (
        <div>
            <h1>My Sites</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card 
                        className={classes.card}
                        onClick={() => setOpen(true)}    
                    >
                        <CardContent className={classes.cardContent}>
                            <Add className={classes.addIcon}/><br/><br/>
                                Add Site
                        </CardContent>
                    </Card>
                </Grid>
                {
                    sites && sites.map(site => (
                        <Grid item xs={12} md={4} key={site.id}>
                            <SiteCard site={site} />
                        </Grid>
                    ))
                }
            </Grid>
            <div>

            </div>

            <AddSite open={open} closeDialog={() => setOpen(false)}/>
        </div>
    )
}
