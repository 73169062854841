import React from 'react'
import { Route } from 'react-router-dom'
import ForgotPassword from '../auth/ForgotPassword'
import Login from '../auth/Login'
import SignUp from '../auth/SignUp'

export default function PublicRoutes() {
    return (
        <>
            <Route exact path='/' component={Login} />
            <Route path='/login' component={Login} />
            <Route path='/forgot' component={ForgotPassword} />
            <Route path='/signup' component={SignUp} />
        </>
    )
}
