import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { Link } from 'react-router-dom'
import { Inbox, PersonOutline } from '@material-ui/icons'

export default function AgentMenu() {
    return (
        <div>
            <Link to={'/'}>
                <ListItem button>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </Link>
            <Link to={'/incoming'}>
                <ListItem button>
                    <ListItemIcon>
                        <Inbox />
                    </ListItemIcon>
                    <ListItemText primary="Incoming Tasks" />
                </ListItem>
            </Link>
            <Link to={'/account'}>
                <ListItem button>
                    <ListItemIcon>
                        <PersonOutline />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                </ListItem>
            </Link>
        </div>
    )
}
