import React from 'react'
import AgentAccount from '../agentviews/AgentAccount'
import IncomingTasks from '../agentviews/tasks/IncomingTasks'
import DashboardView from '../dashboard/DashboardView'
import TaskWorkspace from '../websites/tasks/workspace/TaskWorkspace'
import PrivateRoute from './PrivateRoute'

export default function AgentRoutes() {
    return (
        <>
            <PrivateRoute exact path='/' component={DashboardView} />
            <PrivateRoute exact path='/account' component={AgentAccount} />
            <PrivateRoute exact path='/incoming' component={IncomingTasks} />
            <PrivateRoute path='/workspace/:taskId' component={TaskWorkspace} />
        </>
    )
}
