import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main,
        color: '#fff'
    },
}))

export default function AddTask({ open, close, siteId }) {
    const classes = useStyles()
    const { createTask } = useDb()

    const [state, setState] = useState({
        error: null,
        loading: false,
        data: {}
    })

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data, 
                [e.target.name]: e.target.value
            }
        })
    }

    const handleClose = () => {
        setState({
            error: null,
            loading: false,
            data: {}
        })
        close()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await createTask({
                ...state.data,
                siteId
            })
            handleClose()
            // TODO: Navigate to chat

        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.header}>
                Add Task
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField 
                        onChange={handleChange}
                        name="title"
                        required
                        label="Task Title"
                        { ...textFieldProps }
                    />
                    <TextField 
                        onChange={handleChange}
                        name="description"
                        required
                        label="Task Description"
                        { ...textFieldProps }
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={ state.loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button type="submit" disabled={ state.loading } color="primary" variant="contained" endIcon={<ArrowForward />}>Create Task</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
