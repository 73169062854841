import { makeStyles } from '@material-ui/core'
import React from 'react'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '5px 15px'
    },
    chatBubble: {
        background: theme.palette.primary.main,
        color: '#fff',
        maxWidth: '60%',
        float: 'right',
        clear: 'both',
        borderRadius: 20,
        padding: '10px 20px',
        
    },
    timestamp: {
        float: 'right',
        clear: 'both',
        fontSize: 12,
        color: '#555',
        marginBottom: 5
    }
}))

export default function SenderChat({ chat }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.chatBubble}>
                {chat.message}
            </div>
            <div className={classes.timestamp}>
                <span>{moment(chat.timestamp && chat.timestamp.toDate()).fromNow()}</span>
            </div>
        </div>
    )
}
