import { Button, Card, CardActions, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core'
import { ArrowForward, SportsEsports } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    card: {

    },
    descDiv: {
        padding: '20px 0'
    },
    price: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    }
}))

export default function PlanCard({ plan, selectPlan, loading }) {
    const classes = useStyles()

    return (
        <Card>
            <CardHeader
                title={plan.name}
                avatar={<SportsEsports />}
            />
            <CardContent>
                <div className={classes.descDiv}>
                    {plan.description}
                </div>
                
                <Typography>Monthly Cost:</Typography>
                <Typography className={classes.price} variant="h6">$ {plan.prices[0].unit_amount / 100} / mo</Typography>
                
            </CardContent>
            <CardActions>
                <Button 
                    fullWidth 
                    variant="contained" 
                    color="primary" 
                    endIcon={<ArrowForward />}
                    disabled={loading}
                    onClick={() => selectPlan(plan.prices[0].id)}
                >
                    Select Plan
                </Button>
            </CardActions>
        </Card>
    )
}
