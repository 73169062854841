import { Avatar, Card, CardActions, CardContent, CardHeader, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import { Chat, Send } from '@material-ui/icons'
import React, { useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../../shared/constants'
import ReceiverChat from './ReceiverChat'
import SenderChat from './SenderChat'

const useStyles = makeStyles((theme) => ({
    card: {
        height: 600,
        display: 'flex',
        flexDirection: 'column'
    },
    cardContent: {
        height: '100%'
    },
    actions: {
        marginTop: 'auto',
        padding: '10px 16px',
        
    },
    avatar: {
        background: theme.palette.primary.main
    },
    chatWindow: {
        border: '1px solid lightgrey',
        borderRadius: 5,
        height: '410px',
        overflow: 'scroll'
    },
    form: {
        width: '100%'
    },
    checkBox: {
        padding: '0 0 10px 26px'
    },
    senderChat: {
        textAlign: 'right'
    },
    receiverChat: {
        textAlign: 'left'
    }
}))

export default function ChatWindow({ taskId }) {
    const classes = useStyles()
    const { sendChat, GetTaskChats } = useDb()
    const { currentUser } = useAuth()
    const chats = GetTaskChats(taskId)

    const [state, setState] = useState({
        error: null,
        loading: false,
        enterToSend: false,
        data: {
            message: ''
        }
    })

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await sendChat(state.data.message, taskId)
        setState({
            ...state,
            data: {
                ...state.data,
                message: ''
            }
        })
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                title="Chat"
                avatar={
                    <Avatar className={classes.avatar}>
                        <Chat />
                    </Avatar>
                }
            />
            <CardContent className={classes.cardContent}>
                <div className={classes.chatWindow}>
                    {
                        chats && chats.map(chat => {
                            return chat.sender === currentUser.uid 
                            ?
                            <div key={chat.id} className={classes.senderChat}>
                                <SenderChat chat={chat} /> 
                            </div>
                            : 
                            <div key={chat.id} className={classes.receiverChat}>
                                
                                <ReceiverChat chat={chat} />
                            </div>
                        })
                    }
                </div>
            </CardContent>
            <CardActions className={classes.actions}>
                <form onSubmit={handleSubmit} className={classes.form}>
                    <TextField
                        {...textFieldProps}
                        label="Chat"
                        name="message"
                        value={state.data.message}
                        // multiline
                        required
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <Send />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleChange}
                    />
                </form>
            </CardActions>
        </Card>
    )
}
