import { Container, Grid } from '@material-ui/core'
import React from 'react'

export default function AddOns() {
    return (
        <div>
            <Container>
                <h1>Current Add-Ons Available</h1>
                There are currently no Add-Ons available, check back soon.
                <Grid container spacing={3}>
                    
                </Grid>
            </Container>
        </div>
    )
}
