import { Button, Card, CardActions, CardContent, Grid, makeStyles } from '@material-ui/core'
import { Add, ArrowBack, History } from '@material-ui/icons'
import React, { useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import AddTask from './AddTask'
import ClientTask from './ClientTask'
import CloseTask from './CloseTask'

const useStyles = makeStyles((theme) => ({
    card: {
        height: 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    cardContent: {
        textAlign: 'center'
    },
    addIcon: {
        fontSize: 40,
        color: theme.palette.primary.main,
        textAlign: 'center'
    }
}))

export default function SiteTasks() {
    const { siteId, siteName } = useParams()
    const classes = useStyles()
    const history = useHistory()
    const { GetSiteTasks } = useDb()
    const [addOpen, setAddOpen] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)

    const [selectedTask, setSelectedTask] = useState(null)

    const openConfirmDialog = (task) => {
        setSelectedTask(task)
        setConfirmOpen(true)
    }

    const closeConfirmDialog = () => {
        setConfirmOpen(false)
        setSelectedTask(null)
    }

    const tasks = GetSiteTasks(siteId)

    return (
        <div>
            <h1>Tasks for {siteName}</h1>
            <CardActions>
                <Button onClick={() => history.goBack()} color="primary" startIcon={<ArrowBack />}>Back</Button>
                <Link to={`/closed-tasks/${siteId}`}>
                    <Button color="primary" variant="outlined" startIcon={<History />}>View Closed Tasks</Button>
                </Link>
            </CardActions>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}> 
                    <Card 
                        className={classes.card}
                        onClick={() => setAddOpen(true)}    
                    >
                        <CardContent className={classes.cardContent}>
                            <Add className={classes.addIcon}/><br/><br/>
                            Add Task
                        </CardContent>
                    </Card>
                </Grid>
                {
                    tasks && tasks.map(task => (
                        <Grid item xs={12} md={4} key={task.id}>
                            <ClientTask task={task} confirmClose={() => openConfirmDialog(task)}/>
                        </Grid>
                    ))
                }
            </Grid>

            <AddTask open={addOpen} close={() => setAddOpen(false)} siteId={siteId} />
            <CloseTask open={confirmOpen} close={closeConfirmDialog} taskId={selectedTask && selectedTask.id} />
        </div>
    )
}
