import { Button, Container, Grid } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React, { useState, useEffect } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import PlanCard from './PlanCard'
import { useHistory } from 'react-router-dom'

export default function AddPlan() {
    const { getProducts, checkout } = useDb()
    const history = useHistory()
    const [products, setProducts] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchProducts = async () => {
            const p = await getProducts()
            setProducts(p)
        }
        fetchProducts()
    }, [getProducts])

    const handlePriceSelect = async (price) => {
        setLoading(true)
        await checkout(price)
    }

    return (
        <div>
            <Container>
                <h1>Select a Plan</h1>
                <Button onClick={() => history.goBack()} color="primary" startIcon={<ArrowBack />}>Back</Button>
                <Grid container spacing={3}>
                    {
                        products && products.map(p => (
                            <Grid item xs={12} md={4} key={p.id}>
                                <PlanCard 
                                    plan={p} 
                                    selectPlan={handlePriceSelect} 
                                    loading={loading}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </div>
    )
}
