import { Button, Card, CardHeader, Grid, CardActions, CircularProgress, CardContent, Typography } from '@material-ui/core'
import { AddBox, CreditCard, Person } from '@material-ui/icons'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useDb } from '../../contexts/DatabaseContext'
import { cloudFunctions } from '../../firebase'
import stripeBadge from '../../img/stripe.svg'

export default function Account() {
    const { GetSubscription } = useDb()
    const { logout, currentUser } = useAuth()
    const subscription = GetSubscription()
    const history = useHistory()
    const [loading, setLoading] = useState(false)

    const handlePortalAccess = async () => {
        setLoading(true)
        const functionRef = cloudFunctions
        .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
        const { data } = await functionRef({
            returnUrl: window.location.origin,
            locale: "auto", 
        });
        // setLoading(false)
        window.location.assign(data.url);
    }

    const handleLogout = async () => {
        try {
            await logout()
            history.push('/login')
            window.location.reload()
        }
        catch(err) {
            console.log(err.message)
        }
    }

    return (
        <div>
            <h1>My Account</h1>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader
                            title="Your Profile"
                            avatar={<Person />}
                        />
                        <CardContent>
                            <Typography>Account: {currentUser.email}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleLogout} color="primary">Log Out</Button>
                        </CardActions>
                    </Card>
                </Grid>
                
                <Grid item xs={6}>
                    <Card>
                        <CardHeader
                            title={'Billing'}
                            avatar={<CreditCard />}
                        />
                        <CardContent>
                            <Typography>
                                Your Current Plan: { subscription ? subscription.items[0].price.nickname : 'No Active Plan'}
                            </Typography>
                            {
                                !subscription
                                ?
                                <Link to="/addplan">
                                    <Button color="primary" variant="contained" startIcon={<AddBox />}>Add Plan</Button>
                                </Link>
                                :
                                null
                            }
                            
                        </CardContent>
                        <CardActions>
                            {
                                loading
                                ?
                                <CircularProgress />
                                :
                                <Button disabled={ !subscription } variant="contained" onClick={handlePortalAccess} color="primary">Manage Billing</Button>
                            }
                            <img src={stripeBadge} width={120} alt="Stripe Badge"/>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
