import React from 'react'
import Account from '../account/Account'
import AddPlan from '../account/AddPlan'
import AddOns from '../addons/AddOns'
import DashboardView from '../dashboard/DashboardView'
import MySites from '../websites/MySites'
import SiteTasks from '../websites/tasks/SiteTasks'
import PrivateRoute from './PrivateRoute'
import { Redirect } from 'react-router-dom'
import TaskWorkspace from '../websites/tasks/workspace/TaskWorkspace'
import ClosedTasks from '../websites/tasks/ClosedTasks'

export default function ClientRoutes() {
    return (
        <>
            <Redirect from="/signup" to="/" />
            <PrivateRoute exact path='/' component={DashboardView} />
            <PrivateRoute path='/sites' component={MySites} />
            <PrivateRoute path='/account' component={Account} />
            <PrivateRoute path='/addons' component={AddOns} />
            <PrivateRoute path='/addplan' component={AddPlan} />
            <PrivateRoute path='/site-tasks/:siteId/:siteName' component={SiteTasks} />
            <PrivateRoute path='/closed-tasks/:siteId' component={ClosedTasks} />
            <PrivateRoute path='/workspace/:taskId' component={TaskWorkspace} />
        </>
    )
}
