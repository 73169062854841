import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import { Link } from 'react-router-dom'
import { Computer } from '@material-ui/icons'

export default function ClientMenu() {
    return (
        <div>
            <Link to={'/'}>
                <ListItem button>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </Link>
            <Link to={'/sites'}>
                <ListItem button>
                    <ListItemIcon>
                        <Computer />
                    </ListItemIcon>
                    <ListItemText primary="Your Sites" />
                </ListItem>
            </Link>
            <Link to={'/addons'}>
                <ListItem button>
                    <ListItemIcon>
                        <AddBoxOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add-Ons" />
                </ListItem>
            </Link>
            <Link to={'/account'}>
                <ListItem button>
                    <ListItemIcon>
                        <ContactMailIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Account" />
                </ListItem>
            </Link>
        </div>
    )
}
