import { Button, Card, CardContent, CircularProgress, Container, Grid, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import Attachments from './Attachments'
import ChatWindow from './ChatWindow'

export default function TaskWorkspace() {
    const { taskId } = useParams()
    const { getTask } = useDb()
    const history = useHistory()
    const [task, setTask] = useState()

    useEffect(() => {
        const fetchTask = async () => {
            const t = await getTask(taskId)
            setTask(t)
        }
        fetchTask()
    }, [taskId, getTask])

    return (
        <div>
            {
                task 
                ?
                <Container>
                    <h1>Task</h1>
                    <Typography variant="h6">{task.title}</Typography>

                    <Button color="primary" startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <ChatWindow taskId={taskId} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography>{task.description}</Typography>
                                </CardContent>
                            </Card>
                            <br/>
                            <Attachments taskId={taskId}/>
                        </Grid>
                    </Grid>
                </Container>
                :
                <CircularProgress />
            }
        </div>
    )
}
