import { Avatar, Card, CardActions, CardHeader, CardContent, makeStyles, Button } from '@material-ui/core'
import React, { useState } from 'react'
import BuildIcon from '@material-ui/icons/Build';
import HelpIcon from '@material-ui/icons/Help';
import { Chat, CheckCircle, PersonAdd } from '@material-ui/icons';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { useDb } from '../../../contexts/DatabaseContext';

const useStyles = makeStyles((theme) => ({
    card: {
        height: 250,
        display: 'flex',
        flexDirection: 'column'
    },
    actions: {
        marginTop: 'auto'
    },
    url: {
        fontSize: 12
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: 35
    }
}))

export default function AgentTask({ task, currentUser }) {
    const classes = useStyles()
    const { joinTask } = useDb()

    const [state, setState] = useState({
        error: null,
        loading: false
    })
    
    const handleJoin = async () => {
        setState({
            error: null,
            loading: true
        })
        try {
            await joinTask(task.id)
        }
        catch(err) {
            setState({
                error: err.message,
                loading: false
            })
        }
    }

    return (
        <Card className={classes.card}>
            <CardHeader 
                title={`${task.title}`}
                subheader={task.timestamp && moment(task.timestamp.toDate()).fromNow()}
                avatar={
                    <Avatar>
                        {
                            task.waiting
                            ?
                            <HelpIcon />
                            :
                            <BuildIcon />
                        }
                    </Avatar>
                }
            />
            <CardContent>
                {/* Task Preview */}
            </CardContent>
            <CardActions className={classes.actions}>
                {
                    task.agents && task.agents.includes(currentUser.uid)
                    ?
                    <Link to={`/workspace/${task.id}`}>
                        <Button color="primary" startIcon={<Chat />}>Workspace</Button>
                    </Link>
                    :
                    <Button 
                        disabled={ state.loading }
                        color="primary" 
                        startIcon={<PersonAdd />}
                        onClick={handleJoin}
                    >
                        Join    
                    </Button>
                }
                <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={<CheckCircle />}
                >
                    Close
                </Button>
            </CardActions>
        </Card>
    )
}
