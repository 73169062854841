import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Dashboard from './dashboard/Dashboard'
import { useAuth } from '../contexts/AuthContext'
import { createTheme, ThemeProvider } from '@material-ui/core'
import AdminDashboard from './dashboard/AdminDashboard'
import DatabaseProvider from '../contexts/DatabaseContext'
import PublicRoutes from './routes/PublicRoutes'

const wmysTheme = createTheme({
    palette: {
        primary: {
            main: '#00b1e0',
            contrastText: '#fff'
        },
    }
})

export default function Wrapper() {
    const { currentUser } = useAuth()
    return (
        <BrowserRouter>
            <ThemeProvider theme={wmysTheme}>
                {
                    currentUser && currentUser.uid
                    ?
                        currentUser.role === 'Client'
                        ?
                        <DatabaseProvider>
                            <Dashboard />
                        </DatabaseProvider>
                        :
                        <DatabaseProvider>
                            <AdminDashboard />
                        </DatabaseProvider>
                    :
                    <PublicRoutes />
                }
            </ThemeProvider>
        </BrowserRouter>
    )
}
