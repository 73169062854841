import { Button } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'

export default function AgentAccount() {
    const { logout } = useAuth()
    const [state, setState] = useState({
        error: null,
        loading: false
    })

    const handleLogout = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await logout()
            window.location.reload()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }
    return (
        <div>
            <Button 
                color="primary" 
                variant="contained" 
                startIcon ={<ExitToApp />}
                onClick={handleLogout}
            >
                Log Out
            </Button>
        </div>
    )
}
