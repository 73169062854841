import React, { useContext, useState, useEffect } from 'react'
import { auth, db } from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        return auth.signOut()
    }

    async function createAccount(data) {
        return new Promise(async (res,rej) => {
            const user = await auth.createUserWithEmailAndPassword(data.email, data.password)
            await db.collection('users').doc(user.user.uid).set({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                role: "Client",
                companyName: data.companyName
            })
        })  
    }

    function forgotPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    async function getUserDoc(uid) {
        return await db.collection('users')
        .doc(uid)
        .get().then(doc => {
            return doc.data()
        })
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            if(user) {
                await auth.currentUser.getIdToken(true);
                const decodedToken = await auth.currentUser.getIdTokenResult();
                const agent = decodedToken.claims.agent ? true : false
                const admin = decodedToken.claims.admin ? true : false
                const userData = await getUserDoc(user.uid)
                setCurrentUser({...user, ...userData, agent, admin})
            }
            setLoading(false)
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        forgotPassword,
        logout,
        createAccount
    }

    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
        </AuthContext.Provider>
    )
}
