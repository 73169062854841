import { makeStyles } from '@material-ui/core'
import React from 'react'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '5px 5px'
    },
    chatBubble: {
        background: 'lightgrey',
        color: '#222',
        maxWidth: '60%',
        float: 'left',
        clear: 'both',
        borderRadius: 20,
        padding: '10px 20px',
        margin: '5px 15px'
    },
    timestamp: {
        float: 'left',
        clear: 'both',
        fontSize: 12,
        color: '#555',
        marginBottom: 5,
        marginLeft: 15
    }
}))

export default function ReceiverChat({ chat }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.chatBubble}>
                {chat.message}
            </div>
            <div className={classes.timestamp}>
                <span>{moment(chat.timestamp && chat.timestamp.toDate()).fromNow()}</span>
            </div>
        </div>
    )
}
