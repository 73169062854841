import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link, Redirect, useHistory } from 'react-router-dom'
import Logo from '../../img/Logo.png'
import { useAuth } from '../../contexts/AuthContext';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <span>
        We Manage Your Site, Inc.
      </span>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
      marginBottom: 30
  }
}));

export default function SignUp() {
    const classes = useStyles();
    const { createAccount, currentUser } = useAuth()
    const history = useHistory()

    const [state, setState] = useState({})
    
    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            await createAccount(state)
            history.push('/')
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    if(currentUser) {
        return <Redirect to="/" />
    }
    else return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <img src={Logo} width={80} className={classes.logo} alt="WMYS Logo"/>
            <Typography component="h1" variant="h5">
            Sign Up
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="First Name"
                    name="firstName"
                    autoFocus
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Company Name (Optional)"
                    name="companyName"
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    name="email"
                    type="email"
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Choose a Password"
                    type="password"
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                >
                    Sign Up
                </Button>
                { error ? <span className="error">{error}</span> : null }
            </form>
            <Grid container>
                {/* <Grid item xs>
                    <Link to="/forgot" variant="body2">
                        Forgot password?
                    </Link>
                </Grid> */}
                <Grid item>
                    <Link to="/login" variant="body2">
                        {"Already have an account? Log In"}
                    </Link>
                </Grid>
            </Grid>
        </div>
        <Box mt={8}>
            <Copyright />
        </Box>
        </Container>
    );
}