import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main,
        color: '#fff'
    },
}))

export default function CloseTask({ open, close, taskId }) {
    const classes = useStyles()
    const { closeTask } = useDb()

    const [state, setState] = useState({
        error: null,
        loading: false,
        data: {}
    })

    const handleClose = () => {
        setState({
            error: null,
            loading: false,
            data: {}
        })
        close()
    }

    const handleSubmit = async () => {
        setState({
            error: null,
            loading: true,
            data: {}
        })
        try {
            await closeTask(taskId)
            handleClose()
        }
        catch(err) {
            setState({
                error: err.message,
                loading: false,
                data: {}
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.header}>
                Close Task
            </DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to mark this task as complete? </Typography>
            </DialogContent>
            <DialogActions>
                <Button disabled={ state.loading } color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={ state.loading } startIcon={<CheckCircle />} color="primary" variant="contained" onClick={handleSubmit}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
