import { faWordpress } from '@fortawesome/free-brands-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, CardActions, CardHeader, Button, makeStyles } from '@material-ui/core'
import { Card, CardContent } from '@material-ui/core'
import { Link } from 'react-router-dom'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    card: {
        height: 250,
        display: 'flex',
        flexDirection: 'column'
    },
    actions: {
        marginTop: 'auto'
    },
    url: {
        fontSize: 12
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: 35
    }
}))

export default function SiteCard({ site }) {
    const classes = useStyles()
    return (
        <Card className={classes.card}>
            <CardHeader
                title={site.title}
                action={
                    site.siteType === 'wordpress'
                    ?
                    <FontAwesomeIcon className={classes.icon} icon={faWordpress} />
                    :
                    <Avatar>
                        <FontAwesomeIcon icon={faCode} />
                    </Avatar>
                }
                subheader={<span className={classes.url}>{site.url}</span>}
            />
            <CardContent>
                Current Plan: TODO
            </CardContent>
            <CardActions className={classes.actions}>
                <Link to={`/site-tasks/${site.id}/${site.title}`}>
                    <Button color="primary">Tasks</Button>
                </Link>
            </CardActions>
        </Card>
    )
}
