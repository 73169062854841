import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const app = firebase.initializeApp({
    apiKey: "AIzaSyCR8dK5gea7mr52oTtG1kD1OHCo1Bw7Nr4",
    authDomain: "wmys-production.firebaseapp.com",
    projectId: "wmys-production",
    storageBucket: "wmys-production.appspot.com",
    messagingSenderId: "659851361395",
    appId: "1:659851361395:web:45a902253be4a97f763e06"
});

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const cloudFunctions = app.functions('us-central1');
export const fieldValue = firebase.firestore.FieldValue;
export default app;
